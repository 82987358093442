/***** ACCOUNT PAGES STYLES *****/
.account-page {
  min-height: 100vh;
  background: #0c425d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
.account-page > a {
  display: inherit;
}
@media only screen and (max-width: 560px) {
  .account-page {
    padding: 24px 0 0;
  }
  .account-page > a {
    margin-bottom: 24px;
  }
}
/*** Account Card Styles ***/
.account-card {
  width: 640px;
  max-width: 98%;
  padding: 40px;
}
@media only screen and (max-width: 560px) {
  .account-card {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    padding: 16px 16px 48px;
    min-height: calc(100vh - 128px);
  }
}

/***** LOGIN PAGE STYLES *****/
/*** Tab Styles ***/
.tabs {
  margin: -40px -40px 24px;
  display: flex;
}
.tab {
  width: 50%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AvenirHeavy;
  text-transform: uppercase;
  font-size: 16px;
  background: #eee;
  transition: all 300ms linear;
}
.tab.active {
  background: #fff;
}
.tab:not(.active):hover {
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
@media only screen and (max-width: 560px) {
  .tabs {
    margin: -16px -16px 24px;
  }
  .tab {
    font-size: 12px;
  }
}
/*** Password Reset Success Message ***/
.reset-password-success {
  color: #63c674;
  font-family: AvenirMedium;
  justify-content: center;
}
/*** Social Login Buttons ***/
.login-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  padding: 0 40px 40px;
  margin: 40px -40px;
}
// .login-btns > * {width: 50%; max-width: 240px;}
@media only screen and (max-width: 560px) {
  .login-btns {
    display: block;
    padding: 0 16px 24px;
    margin: 24px -16px;
  }
  // .login-btns > * {width: 100%; max-width: 100%;}
}
// /* Google Sign In Button Styles --> REMOVED LIB */
// .g-signin2 {margin-right: 16px;}
// .g-signin2 .abcRioButton {
//   width: 100% !important;
//   height: 48px !important;
//   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 0 5px -2px rgba(0,0,0,0.12), 0 2px 18px 2px rgba(0,0,0,0.08) !important;
//   border: 1px solid rgba(0, 0, 0, .04);
//   border-radius: 3px !important;
// }
// .g-signin2 .abcRioButton:hover {box-shadow: none !important; transition: box-shadow 300ms linear;}
// .g-signin2 .abcRioButtonContentWrapper {display: flex; height: 100%; align-items: center; padding-left: 16px; padding-right: 32px;}
// .g-signin2 .abcRioButtonIcon {padding: 0 !important; margin-right: 16px;}
// .g-signin2 .abcRioButtonIcon > div,  .g-signin2 .abcRioButtonIcon > div svg {height: 24px !important; width: 24px !important;}
// .g-signin2 .abcRioButtonContents {font-weight: normal; color: rgba(0, 0, 0, .87); font-size: 16px !important; font-family: AvenirRoman;}
// @media only screen and (max-width: 624px){
//   .g-signin2 .abcRioButtonContents {font-size: 13px !important;}
// }
// @media only screen and (max-width: 560px){
//   .g-signin2 {margin-right: 0; margin-bottom: 16px;}
// }
/* Facebook Button Styles */
.facebook-button {
  border-radius: 3px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.04);
  height: 48px;
  width: 100%;
  text-align: left;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-family: AvenirRoman;
  cursor: pointer;
  background: #fff;
}
.facebook-button i {
  font-size: 24px;
  color: #39559f;
  margin-right: 8px;
}
.facebook-button:hover {
  box-shadow: none !important;
  transition: box-shadow 300ms linear;
}
@media only screen and (max-width: 624px) {
  .facebook-button {
    font-size: 13px !important;
  }
}
/* Account Form Styles */
.account-form {
  max-width: 416px;
  margin: 0 auto;
}
.account-form .input-container {
  margin-bottom: 16px;
}
.name-input-containers .input-container {
  width: calc(50% - 8px);
}
@media only screen and (max-width: 560px) {
  .name-input-containers .input-container {
    width: 100%;
  }
}
