html,
body,
#root {
  height: 100%;
}

/***** Your Israel PAGE STYLES *****/
.your-israel-page {
  @extend .trip-page;
}

/*** Your Israel Page - Form Styles ***/
.your-israel-page-form-container {
  @extend .trip-page-form-container;
  padding-top: 0;
}
.your-israel-form-banner {
  height: 96px;
  background: $accent-color-dark;
  padding: 0 32px;
  display: flex;
  align-items: center;
  margin: -32px -32px 24px;
  border-radius: 3px 3px 0 0;
}
.your-israel-form-banner img {
  height: 64px;
}
.your-israel-form-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 32px;
}
.your-israel-form-label {
  font-size: 12px;
  color: $accent-text;
  width: 140px;
  margin-right: 32px;
}
@media only screen and (max-width: 1040px) {
  .your-israel-page-form-container {
    width: 100%;
  }
}
@media only screen and (max-width: 560px) {
  .your-israel-page-form-container {
    padding: 0;
  }
  .your-israel-page-form-container .card {
    padding: 0;
    box-shadow: none;
    border: none;
  }
  .your-israel-form-banner {
    height: 72px;
    padding: 0 16px;
    margin: 0 0 32px 0;
    border-radius: 0;
  }
  .your-israel-form-banner img {
    height: 40px;
  }
  .your-israel-page-form .xxl-text {
    font-size: 18px;
    margin: 0 -16px 32px;
    padding: 0 16px 16px;
  }
  .your-israel-page-form .medium-text.fw-700 {
    font-size: 14px;
  }
  .your-israel-form-section {
    padding: 0 16px 24px;
  }
  .your-israel-form-section:last-child {
    margin-bottom: 0;
  }
  .mobile-block .your-israel-form-label {
    margin-bottom: 4px;
  }
  .your-israel-form-section .mobile-block .radio-container {
    width: 100%;
  }
  .pegiyourisraelsha-form-section
    .mobile-block
    .radio-container
    input[type="radio"]
    + label {
    width: 50% !important;
  }
}

/*** Your Israel Enrollment States Styles ***/
.your-israel-page-form-state {
  @extend .trip-page-form-state;
}
@media only screen and (max-width: 560px) {
  .your-israel-page-form-state {
    padding: 64px 16px 72px;
  }
}

/* Your Israel Enrollment Form - Classes Styles */
.course-classes {
  margin-top: 40px;
}
.course-classes-schedule-details {
  display: grid;
  grid-template-columns: 176px 120px 1fr;
  margin-left: 48px;
}
.accept-course-terms-checkbox
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
}
@media only screen and (max-width: 560px) {
  .course-classes {
    margin-top: 24px;
  }
  .course-classes-schedule-details {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-left: 0;
  }
}
