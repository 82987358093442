/***** CURSOR STYLES *****/
.pointer {
  cursor: pointer;
}

/***** LINE HEIGHT STYLES *****/
.line-height-double {
  line-height: 1.5;
}
.line-height-large {
  line-height: 1.25;
}
