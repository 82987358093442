[mobilemenuopen] {
  overflow: hidden;
  height: 100vh;
}

/*** Menu Icon Styles ***/
.menu-icon-wrapper {
  position: absolute;
  top: 8px;
  z-index: 100;
  width: 20px;
  height: 40px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.menu-icon {
  position: relative;
  top: 20px;
  width: 20px;
  height: 2px;
  background: $accent-color;
  display: block;
  transform-origin: center;
  transition: 0.5s ease-in-out;
}
.menu-icon:after,
.menu-icon:before {
  transition: 0.5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: $accent-color;
}
.menu-icon:before {
  top: -6px;
}
.menu-icon:after {
  bottom: -6px;
}

/*** Menu Content Styles ***/
.mobile-menu-content {
  z-index: 200;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #1c96d2 0%, #80caee 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  transition: 0.25s 0s ease-in-out;
}
.mobile-nav-link a {
  font-size: 32px;
  margin-bottom: 48px;
  color: #fff;
  font-family: AvenirBook;
  display: block;
  text-align: center;
}
.mobile-nav-footer {
  position: absolute;
  bottom: 64px;
  color: #fff;
  font-size: 10px;
}

/*** Menu Open Styles ***/
.menu-open .menu-icon-wrapper {
  z-index: 400;
}
.menu-open .menu-icon-wrapper .menu-icon {
  transform: rotate(45deg);
  background: #fff;
}
.menu-open .menu-icon-wrapper .menu-icon:after {
  transform: rotate(90deg);
  bottom: 0;
  background: #fff;
}
.menu-open .menu-icon-wrapper .menu-icon:before {
  transform: rotate(90deg);
  top: 0;
  background: #fff;
}
.menu-open .menu-icon-wrapper + .mobile-menu-content {
  opacity: 1;
  visibility: visible;
}
