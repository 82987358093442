@font-face {
  font-family: AvenirBlack;
  src: url(/fonts/Avenir-Black.woff);
}
@font-face {
  font-family: AvenirHeavy;
  src: url(/fonts/Avenir-Heavy.woff);
}
@font-face {
  font-family: AvenirMedium;
  src: url(/fonts/Avenir-Medium.woff);
}
@font-face {
  font-family: AvenirRoman;
  src: url(/fonts/Avenir-Roman.woff);
}
@font-face {
  font-family: AvenirBook;
  src: url(/fonts/Avenir-Book.woff);
}

body {
  font-family: AvenirRoman;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
a {
  text-decoration: none;
  color: inherit;
}

/***** FONT WEIGHT STYLES *****/
.fw-900 {
  font-family: AvenirBlack;
}
.fw-700 {
  font-family: AvenirHeavy;
}
.fw-500 {
  font-family: AvenirMedium;
}
.fw-400 {
  font-family: AvenirRoman;
}
.fw-300 {
  font-family: AvenirBook;
}

/***** FONT SIZE STYLES *****/
.xs-text {
  font-size: 10px;
}
.small-text {
  font-size: 12px;
}
.medium-text {
  font-size: 16px;
}
.large-text {
  font-size: 18px;
}
.xl-text {
  font-size: 20px;
}
.xxl-text {
  font-size: 24px;
}
.xxxl-text {
  font-size: 40px;
}
@media only screen and (max-width: 560px) {
  .mobile-xl-text {
    font-size: 20px;
  }
  .mobile-large-text {
    font-size: 18px;
  }
  .medium-text {
    font-size: 14px;
  }
  .mobile-reg-text {
    font-size: 14px;
  }
}

/***** FONT STYLES *****/
.link-text {
  color: $accent-color;
  font-family: AvenirMedium;
  transition: opacity 300ms linear;
  cursor: pointer;
  text-decoration: none;
}
.link-text.material-icons {
  font-family: "Material Icons";
}
.link-text:hover {
  opacity: 0.5;
}
.link-text-no-hover:hover {
  opacity: 1;
}
.link-text.no-hover {
  @extend .link-text-no-hover;
}
.link-text.disabled {
  @extend .link-text-no-hover;
  cursor: not-allowed;
  color: $accent-text;
}
.link-text-heavy {
  font-family: AvenirHeavy;
}
.link-text .back-arrow {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 8px;
}
.link-with-icon i {
  color: $accent-text-light;
  transition: color 300ms linear;
}
.link-with-icon:hover i {
  color: $accent-color-dark;
}
.page-wrapper-mobile .link-with-icon i {
  color: $accent-color-dark;
}
.link-text-secondary {
  transition: opacity 300ms linear;
}
.link-text-secondary:hover {
  opacity: 0.6;
  cursor: pointer;
}
.page-title {
  font-family: AvenirBlack;
  font-size: 34px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #edecec;
}
.strike-through {
  text-decoration: line-through;
}
.italic {
  font-style: italic;
}

/***** TEXT COLOR STYLES ****/
.accent-text-light {
  color: $accent-text-light;
}
.accent-text {
  color: $accent-text;
}
.accent-text-dark {
  color: $accent-text-dark;
}
.accent-text-secondary {
  color: $accent-color;
}
.error-text {
  color: $error-color;
}

/***** TEXT TRANSFORM STYLES *****/
.uppercase-text {
  text-transform: uppercase;
}
.nowrap-text {
  white-space: nowrap;
}

/***** TEXT ALIGN STYLES *****/
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
@media only screen and (max-width: 560px) {
  .mobile-text-center {
    text-align: center;
  }
}

/***** TEXT DECORATION STYLES *****/
.underline-text {
  text-decoration: underline;
}
