html,
body,
#root {
  height: 100%;
}

/***** TRIP PAGE STYLES *****/
.trip-page {
  padding: 40px;
  min-height: calc(100vh - 72px);
  background: #fff;
  height: 100%;
}
.trip-page .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
@media only screen and (max-width: 1040px) {
  .trip-page {
    min-height: calc(100vh - 56px);
  }
}
@media only screen and (max-width: 768px) {
  .trip-page {
    padding: 16px 0;
  }
}
@media only screen and (max-width: 560px) {
  .trip-page {
    padding: 0;
  }
}

/*** Trip Registration - Nav Stepper Styles ***/
.trip-registration-nav {
  position: sticky;
  top: 72px;
  margin: -40px;
  padding: 16px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  background: #fff;
  z-index: 1;
}
.stepper-line {
  position: relative;
  height: 1px;
  width: calc(100% - 190px);
  background: rgba(0, 0, 0, 0.24);
  top: -12px;
  margin: 0 -96px;
}
.stepper-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.stepper-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.stepper-step:first-child {
  margin-left: -96px;
}
.stepper-step:last-child {
  margin-right: -96px;
}
.stepper-step-circle {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.24);
  background: #f5f5f5;
  z-index: 1;
  position: relative;
}
.stepper-step-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
  margin-top: 8px;
}
.stepper-step.active .stepper-step-circle {
  border-color: $accent-color;
  background: $accent-color;
}
.stepper-step.active .stepper-step-title {
  color: $accent-color;
}
@media only screen and (max-width: 1040px) {
  .banner-wrapper + .header + .page-wrapper .trip-page-stepper {
    top: 104px;
  }
  .trip-page-stepper {
    top: 56px;
  }
}
@media only screen and (max-width: 768px) {
  .trip-page-stepper {
    margin-bottom: 24px;
  }
  .stepper-step:first-child {
    margin-left: -72px;
  }
  .stepper-step:last-child {
    margin-right: -72px;
  }
}
@media only screen and (max-width: 560px) {
  .trip-page-stepper {
    margin: 0;
    overflow: hidden;
  }
  .stepper-step-title {
    font-size: 12px;
  }
  .stepper-step:first-child {
    margin-left: -120px;
  }
  .stepper-step:last-child {
    margin-right: -120px;
  }
}
@media only screen and (max-width: 440px) {
  .stepper-btns {
    flex-direction: column-reverse;
  }
  .stepper-btns .btn-light {
    margin-top: 0;
    text-align: center;
    background: transparent;
    text-decoration: underline;
    font-size: 12px;
    text-transform: none;
    color: $accent-text-dark;
    width: 100%;
    border: none;
  }
}

/*** Trip Page - Form Styles ***/
.trip-page-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-self: flex-start;
  padding-top: 64px;
}
.trip-page-form-container .trip-page-form {
  width: 100%;
}
.trip-page-form {
  width: calc(100% - 424px);
}
.trip-page-form .xxl-text {
  font-size: 22px;
  border-bottom: 1px solid #edecec;
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.trip-form-section:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 32px;
}
.travel-trip-form .trip-form-section:not(:last-child) {
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.trip-form-section-inner:not(:last-child) {
  @extend .trip-form-section;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.trip-form-section .subsection:not(:last-child) {
  padding-bottom: 40px;
  .mb-24:last-child {
    margin-bottom: 0px;
  }
}
.trip-form-section .section-title {
  font-family: AvenirBlack;
  font-size: 22px;
  margin-bottom: 16px;
}
.trip-form-section .subsection-title {
  font-family: AvenirBlack;
  font-size: 16px;
  margin-bottom: 16px;
}
.trip-form-section .subsection-title.mb-24 {
  margin-bottom: 24px;
}
.trip-form-label {
  color: $accent-text-dark;
  line-height: 1.2;
  margin-bottom: 8px;
}
.trip-form-label.error-text {
  color: $error-color;
}
@media only screen and (max-width: 1040px) {
  .trip-page-form-container {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  input, .custom-input { 
    font-size: 14px !important; 
  }

  .trip-form-section .subsection:not(:last-child) {
    border-bottom: 1px solid #edecec;
    margin: 0px -16px 24px;
    padding: 0px 16px 24px;
  }
  .trip-form-section .subsection-title.mb-24 {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 560px) {
  .trip-page-form-container {
    padding-top: 24px;
  }
  .trip-page-form .xxl-text {
    font-size: 18px;
    margin: 0 -16px 32px;
    padding: 0 16px 16px;
  }
  .trip-page-form .medium-text.fw-700 {
    font-size: 14px;
  }
  .trip-form-section:not(:last-child) {
    margin: 0 -16px 24px;
    padding: 0 16px 24px;
  }
  .trip-form-section .mobile-block .radio-container {
    width: 100%;
  }
  .trip-form-section
    .mobile-block
    .radio-container
    input[type="radio"]
    + label {
    width: 50% !important;
  }
}
/* Shliach View Style */
.shliach-view-header {
  position: fixed;
  z-index: 2;
  background: #fff;
  height: 72px;
  top: 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
}
/* Overview Styles */
.card.track-card {
  padding: 16px 16px 24px 48px;
  position: relative;
}
.card.track-card:not(:last-child) {
  margin-bottom: 16px;
}
.disabled-track {
  opacity: 0.5;
  cursor: not-allowed;
  margin-bottom: 8px;
}
.card.track-card .radio-button-container {
  margin-left: -32px;
}
.card.track-card .radio-button-container input:checked + label,
.card.track-card .radio-button-container input:not(:checked) + label {
  padding-left: 32px;
  font-size: 16px;
}
.isFullError {
  color: $accent-color-secondary;
  position: absolute;
  bottom: 8px;
  font-size: 12px;
}
/* Overview Travel Styles */
.travel-note {
  color: #0D324E;
  background-color: #E7EDF0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  margin-bottom: 24px;
  div {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 20px;
  }
}
.trip-overview-card {
  @extend .card;
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 40px;
}
.trip-overview-card.confirmed {
  border: 2px solid #33B7AE;
  .confirmation-title {
    color: #33B7AE;
  }
}
.trip-overview-card.error {
  border: 2px solid $error-color;
  .confirmation-title {
    color: $error-color;
  }
}
.trip-overview-card .confirmation-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #B4B5B9;
  font-size: 22px;
  font-family: AvenirHeavy;
  margin-bottom: 24px;
}
.trip-overview-card .apply-title {
  line-height: 1.2;
  font-family: AvenirBlack;
  font-size: 34px;
  margin-bottom: 16px;
}
.trip-overview-card .trip-dates {
  display: flex;
  align-items: center;
  font-family: AvenirBlack;
  font-size: 16px;
  i {
    color: #2774AE;
    margin-right: 8px;
    font-size: 20px;
  }
}
.trip-overview-card .description {
  line-height: 1.5;
}
.trip-overview-card .trip-fees {
  margin: 32px 0;
}
.trip-overview-card .trip-fees > div:first-of-type {
  @extend .two-column-grid;
  grid-template-columns: 90px 1fr;
}
.trip-overview-card .deposit-note {
  color: $accent-text-dark;
  line-height: 1.2;
  margin-top: 12px;
}
.trip-overview-card .profile-link {
  @extend .link-text;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  margin: 16px 0;
}
.trip-overview-card .profile-info-grid {
  @extend .two-column-grid;
  @extend .mobile-one-column-grid;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 768px) {
  .travel-note {
    align-items: flex-start;
    div {
      align-items: flex-start;
    }
  }

  .trip-overview-card {
    padding: 16px;
    margin-bottom: 16px;
  }
  .trip-overview-card:last-of-type {
    margin-bottom: 40px;
  }
  .trip-overview-card .confirmation-title {
    font-size: 16px;
    margin-bottom: 16px;
    i {
      font-size: 20px;
    }
  }
  .trip-overview-card .confirmation-title.collapsed {
    margin-bottom: 0px;
  }
  .trip-overview-card .apply-title {
    font-size: 20px;
  }
  .trip-overview-card .trip-dates {
    font-size: 14px;
    font-family: AvenirHeavy;
  }
  .trip-overview-card .description {
    font-size: 13px;
    color: $accent-text-dark;
  }
  .trip-overview-card .trip-fees {
    margin: 16px 0;
  }
  .trip-overview-card .deposit-note {
    font-size: 12px;
  }
  .trip-overview-card .profile-info-grid {
    margin-top: 12px;
    margin-bottom: 16px;
    .two-column-grid {
      grid-template-columns: 128px 1fr;
    }
    .contact-info-grid {
      display: grid;
      grid-template-columns: 128px 1fr;
      grid-gap: 16px;
    }
    .contact-info-grid {
      line-height: 1.2;
      .mb-16 { margin-bottom: 0px; }
      .mr-16 { display: block; margin-bottom: 4px; }
    }
  }
  .trip-overview-card .confirmation-container {
    border-top: 1px solid #edecec;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
}
/* Application Styles */
.trip-form-section .dropzone .uppercase-text {
  text-transform: unset;
  color: unset;
}
.trip-form-section .profile-pic-container img {
  width: 100px;
  height: 100px;
  margin-right: 26px;
}
.trip-form-section .file-preview-modal object {
  height: 60vh;
  width: 100%;
}
.trip-form-section .tshirt-size .custom-select > div:first-of-type {
  height: auto;
}
/* Travel Styles */
.trip-form-section .emergency-contacts:not(:last-of-type) {
  margin-bottom: 16px;
}
.trip-form-section .emergency-contacts > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  .phone-input, .intl-tel-input {
    height: 18px;
    border: none;
  }
  .input-container.error {
    position: relative;
    .error-text {
      display: block;
      position: absolute;
      bottom: -16px;
      font-size: 12px;
    }
  }
}
.trip-form-section .id-info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}
.trip-form-section .id-tooltip-container .tooltip {
  width: 300px;
  height: 60px;
  text-wrap: wrap;
  line-height: 1.2;
}
.trip-form-section .ktn-tooltip-container {
  height: 10px;
  margin-top: -6px;
  .tooltip {
    right: -24px;
    left: unset;
    width: 280px;
    height: 48px;
    text-transform: none;
    text-wrap: wrap;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 768px) {
  .trip-form-section .profile-pic-container img {
    width: 60px;
    height: 60px;
    margin-right: 32px;
  }
}
@media only screen and (max-width: 560px) {
  .trip-form-section .emergency-contacts > div {
    grid-template-columns: 1fr;
  }
  .trip-form-section .id-info-grid {
    grid-template-columns: 1fr;
  }
  .trip-form-section .id-tooltip-container .tooltip {
    left: -48px;
    height: 44px;
  }
  .trip-form-section .ktn-tooltip-container .tooltip {
    right: -48px;
    height: 32px;
  }
}
/* Tour Styles */
.trip-form-section .tour:not(:last-child) {
  border-bottom: 1px solid #edecec;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.trip-form-section .tour .input-container {
  padding: 0;
  border: none;
}
.tour .isFullError {
  position: relative;
  top: 1px;
}
.tour .scheduleOverlapError {
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: $error-color;
  font-size: 12px;
  white-space: pre-wrap;
}
.trip-form-section .tour-schedule .input-container {
  max-width: initial;
}
.tour-schedule .input-container label {
  color: initial;
  text-transform: none;
}
/* Term Styles */
.long-term-text {
  background: #fafafa;
  border: 1px solid #edecec;
  border-radius: 3px;
  max-height: 120px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.4;
  color: $accent-text-dark;
  padding: 8px;
  white-space: pre-wrap;
}
.term:not(:last-child) {
  @extend .trip-form-section-inner;
}
.travel-term:not(:last-child) {
  margin-bottom: 24px;
}
/* Payment Styles */
.checkout-summary {
  max-width: 280px;
  width: 280px;
}
.trip-form-section .input-container {
  max-width: 280px;
}
.trip-form-section .nested-input-container .input-container {
  border: none;
  padding: 0;
}
.promo-code-tag {
  border: 1px solid $accent-color;
  background: rgba(83, 183, 232, 0.08);
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 8px 12px;
  color: $accent-color;
  font-size: 14px;
}
.apply-reward-card {
  padding: 12px;
  border: 1px solid #edecec;
  border-radius: 3px;
  width: 280px;
  margin: 16px 0;
}
.trip-form-checkout-btns {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.trip-form-checkout-btns .btn {
  width: 176px;
}
.digital-pay-container {
  display: flex;
  align-items: center;
  margin-left: 124px;
  margin-bottom: -16px;
}
.digital-pay-container > p:first-child { 
  margin-right: 42px; 
}
.digital-pay-btn {
  background-color: #f3893d;
  border: 1px solid #f3893d;
  color: #fff;
  width: 280px;
  line-height: 1.2;
  text-transform: none;
}
.digital-pay-btn:hover {
  background-color: #fff;
  color: #f3893d;
}
.trip-form-section .input-container-select__control {
  border: none;
  background: transparent;
  position: relative;
  top: -4px;
  font-size: 12px;
  left: -8px;
  width: calc(100% + 16px);
  min-height: auto;
  height: 24px;
}
.trip-form-section .input-container-select__control--is-focused {
  border: none;
  outline: none !important;
  box-shadow: none;
}
.trip-form-section .input-container-select__indicator-separator {
  display: none;
}
.trip-form-section .input-container-select__clear-indicator {
  padding-right: 0;
  margin-right: -4px;
}
.installments div {
  border: 1px solid #edecec;
  border-radius: 3px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 56px;
}
.cc-info-form .custom-input {
  width: 240px;
  display: grid;
  align-items: center;
}
.cc-info-form .custom-select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 116px;
  border-radius: 3px;
  font-size: 14px;
}
.cc-info-form .custom-input.StripeElement--empty {
  opacity: 0.6 !important;
}
.billing-address-form .custom-input {
  width: 336px;
}
.billing-address-form .custom-input:disabled {
  opacity: 1;
}
.billing-address-form .custom-select,
.billing-address-form .custom-select > select {
  border: 1px solid #edecec;
  background: transparent;
  height: 32px;
  width: 336px;
  max-width: 100%;
  border-radius: 3px;
  font-size: 14px;
  position: relative;
  padding-left: 4px;
  color: #555;
}
.billing-address-form .custom-select > select:focus {
  border: none;
  outline: none;
}
.billing-address-form .custom-select.error {
  border: 1px solid $error-color;
}
.billing-address-form .custom-select > select {
  border: none;
  width: calc(100% + 8px);
}
@media only screen and (max-width: 560px) {
  .payment-options .radio-button-container .flex:first-child {
    display: block;
  }
  .installments {
    margin-left: 32px;
  }
  .cc-info-form .custom-checkbox-container {
    margin-bottom: 8px;
  }
  .cc-info-form .custom-select,
  .cc-info-form .custom-input {
    width: 100%;
  }
  .billing-address-form .custom-select,
  .billing-address-form .custom-input {
    width: 100%;
  }
  .trip-form-checkout-btns {
    flex-direction: column-reverse;
    bottom: 0;
    background: #fff;
    margin-bottom: 16px;
  }
  .trip-form-checkout-btns .mt-24 {
    margin-top: 0;
    width: 100%;
  }
  .trip-form-checkout-btns .btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
  }
  .trip-form-checkout-btns .btn-light {
    margin-top: 16px;
  }
}

/*** Trip Page - Billing Summary Styles ***/
.billing-summary {
  width: 384px;
  max-width: 100%;
  margin-left: 48px;
  position: sticky;
  top: 160px;
}
.billing-summary-top-section {
  border-bottom: 1px solid #edecec;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.billing-summary-row {
  display: grid;
  grid-template-columns: 136px calc(20% - 48px) calc(40% - 60px) calc(
      40% - 75px
    );
  grid-gap: 16px;
  margin-bottom: 16px;
}
.billing-summary-row p:nth-child(2) {
  text-align: center;
}
.billing-summary-row p:nth-child(3),
.billing-summary-row p:nth-child(4) {
  text-align: right;
}
.trip-btn-loader svg#loading {
  height: 24px;
  margin-top: 24px;
}
@media only screen and (max-width: 1072px) {
  .billing-summary {
    width: 352px;
  }
}
@media only screen and (max-width: 1040px) {
  .trip-page-form-container + div .billing-summary {
    display: none;
  }
  .trip-page-form-container.last-step + div .billing-summary {
    display: block;
  }
  .trip-page-form-container.last-step + div {
    border-top: 1px solid #edecec;
    margin: 0 -16px;
    padding: 0 16px;
  }
  .billing-summary {
    display: block;
    margin-left: 0;
    box-shadow: none;
    border: none;
    padding: 24px 0 0;
    width: calc(100% + 80px);
  }
}

/*** Trip Page - Registration States Styles ***/
.trip-page-form-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: calc(100vh - 200px);
  padding-top: 64px;
  padding-bottom: 72px;
}
.trip-page-form-state p {
  padding: 0 24px;
  text-align: center;
  line-height: 1.4;
}
.SocialMediaShareButton {
  border-radius: 3px;
  padding: 4px 8px;
  color: #fff;
  width: 240px;
  cursor: pointer;
  transition: box-shadow 300ms linear;
}
.SocialMediaShareButton:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
}
.SocialMediaShareButton:focus {
  outline: none;
}
.SocialMediaShareButton > .flex > div {
  height: 40px !important;
  width: 40px !important;
}
.SocialMediaShareButton svg {
  height: 40px;
  width: 40px;
}
.SocialMediaShareButton--facebook {
  background: #3a5998;
}
.SocialMediaShareButton--twitter {
  background: #00aced;
  margin: 0 24px;
}
.SocialMediaShareButton--whatsapp {
  background: #2cb642;
}
@media only screen and (max-width: 560px) {
  .trip-page-form-state p.fw-700.mt-24 {
    font-size: 24px !important;
  }
  .trip-page-form-state p.large-text {
    font-size: 14px;
  }
  .trip-page-form-state img {
    height: unset;
    width: 80vw;
  }
}
