/***** INPUT CONTAINER STYLES *****/
.input-container {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 8px 16px;
}
.input-container.error {
  border-color: $error-color;
}
.input-container label {
  font-size: 10px;
  font-family: AvenirMedium;
  color: $accent-text;
  text-transform: uppercase;
  display: block;
  margin-bottom: 4px;
}
.input-container .radio-container label {
  margin-bottom: 0;
}
.input-container input {
  border: none;
  width: 100%;
  font-size: 13px;
  background: transparent;
}
.input-container input:focus {
  outline: none;
}
.input-container-outline {
  border: 1px solid $accent-color;
  transition: border 300ms linear;
}
.input-container select {
  width: calc(100% + 8px);
  border: none;
  background: transparent;
  position: relative;
  left: -8px;
}
.input-container select:focus {
  outline: none;
  border: none;
}
.input-container.toggle-container {
  border: none;
  padding: 0;
}
@media only screen and (max-width: 560px) {
  .page-wrapper-mobile .input-container {
    height: 56px;
  }
  .page-wrapper-mobile .input-container input {
    font-size: 16px;
  }
}

/***** CUSTOM INPUT STYLES *****/
.custom-input {
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 0 8px;
  height: 32px;
  transition: border 300ms linear;
  background: #fff;
  font-family: AvenirRoman;
  font-size: 13px;
}
.custom-input:focus {
  border-color: $accent-color;
  outline: none;
}
.custom-input::placeholder {
  color: rgba(0, 0, 0, 0.24);
}
.custom-input.error {
  border-color: $error-color;
}
.custom-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
input.custom-input:read-only {
  border: none;
  padding: 0;
}
.custom-input.percentage-input {
  background: url("/images/percentage.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 96% center;
}
.input-container.custom-input.dollar-input {
  background: url("/images/dollar.svg");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 8px center;
  padding-left: 24px;
}
input.custom-input.dollar-input:read-only {
  padding-left: 8px;
}
label.error + .custom-input {
  border: 1px solid $error-color;
}
@media only screen and (max-width: 560px) {
  .page-wrapper-mobile .custom-input {
    font-size: 16px;
  }
  .page-wrapper-mobile .custom-input {
    -webkit-appearance: none;
  }
}

/***** CUSTOM SELECT STYLES *****/
.custom-select {
  width: 100%;
}
.custom-select > div,
.custom-select > div:hover {
  border-color: #edecec;
  padding: 0 8px;
}
.custom-select.custom-select--is-disabled > div {
  opacity: 0.5;
  background: transparent;
}
.custom-select.error > div {
  border-color: $error-color;
}
.custom-select > div:first-of-type {
  height: 32px;
  min-height: auto;
  padding: 0;
}
// .custom-select > div.custom-select__control {border: none; height: 30px;}
.custom-select * {
  font-size: 13px;
  font-family: AvenirRoman;
}
.custom-select__option--is-focused {
  background: rgba(12, 66, 93, 0.1) !important;
}
.custom-select__option--is-selected {
  background: $accent-color-dark !important;
}
.custom-select__placeholder {
  white-space: nowrap;
}
.css-15k3avv {
  z-index: 3 !important;
}
.custom-select .custom-select__value-container {
  height: 30px;
  padding-top: 0;
  position: relative;
  padding: 0;
}
.custom-select__indicator.custom-select__dropdown-indicator {
  padding: 0 0 0 8px;
}
.custom-select__menu {
  padding: 0 !important;
}

/***** PHONE INPUT STYLES ****/
.phone-input {
  border: 1px solid #edecec;
  height: 32px;
  border-radius: 3px;
}
.phone-input.error {
  border: 1px solid $error-color;
}
.intl-tel-input {
  display: flex;
  height: 30px;
}
.iti-mobile .intl-tel-input.iti-container {
  height: auto;
}
.intl-tel-input > * {
  border: none;
  outline: none;
}
.intl-tel-input .selected-flag {
  background-color: transparent !important;
  border-right: none;
  display: flex;
  align-items: center;
  z-index: 0 !important;
  padding-left: 0 !important;
  width: 72px !important;
}
.intl-tel-input .selected-flag:after {
  content: "";
  height: 18px;
  width: 1px;
  background: #edecec;
  border: none;
  left: 44px;
  top: 0;
  position: absolute;
}
.intl-tel-input .selected-dial-code {
  color: black;
  font-size: 11px;
  padding-left: 50px !important;
  padding-top: 3px;
}
.intl-tel-input .selected-flag .iti-arrow {
  right: 32px;
}
.intl-tel-input input {
  margin-left: 8px !important;
  background: transparent;
  max-width: 100%;
  font-size: 11px !important;
  // width: 84px !important;
  padding: 0 !important;
}
.intl-tel-input *:focus {
  outline: none;
}
.intl-tel-input.allow-dropdown .flag-container {
  width: auto !important;
  position: relative !important;
}
.intl-tel-input.allow-dropdown.expanded .flag-container {
  pointer-events: none;
}
.intl-tel-input .country-list {
  pointer-events: all;
}

/***** DATE INPUT STYLES *****/
// Make native date inputs clickable - https://stackoverflow.com/a/45461709
// input[type="date"]::-webkit-calendar-picker-indicator {
//   background: transparent;
//   bottom: 0;
//   color: transparent;
//   cursor: pointer;
//   height: auto;
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: auto;
// }
// input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {z-index: 1;}

/***** RADIO BUTTON (LABELS) STYLES *****/
.radio-container {
  display: inline-flex;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  height: 32px;
}
.radio-container.error {
  border: 1px solid $error-color;
}
.radio-container.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.radio-container input[type="radio"] {
  display: none;
}
.radio-container input[type="radio"] + label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $accent-text-dark;
  width: auto;
  font-family: AvenirMedium;
}
.radio-container input[type="radio"]:first-child + label {
  border-right: 1px solid #e0e0e0;
}
.radio-container input[type="radio"]:checked + label {
  background: $accent-color;
  font-family: AvenirHeavy;
  color: white;
  transition: background 300ms linear;
}
.radio-container input[type="radio"]:disabled + label {
  cursor: not-allowed;
}

/***** CUSTOM RADIO BUTTON STYLES *****/
.radio-button-container input:checked,
.radio-button-container input:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio-button-container input:checked + label,
.radio-button-container input:not(:checked) + label {
  position: relative;
  padding-left: 4px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 13px;
  margin-right: 16px;
  top: -2px;
}
.radio-button-container input:checked + label.inline,
.radio-button-container input:not(:checked) + label.inline {
  display: inline;
}
.radio-button-container input:checked + label:before,
.radio-button-container input:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fbfbfc;
}
.radio-button-container input:checked + label:before {
  border: 1px solid rgba(0, 0, 0, 0.54);
}
.radio-button-container input:checked + label:after,
.radio-button-container input:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio-button-container input:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio-button-container input:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.radio-button-container .radio-label {
  color: #76838f;
  font-size: 13px;
}
.radio-button-container input:disabled + label {
  cursor: not-allowed;
  opacity: 0.5;
}

/***** CUSTOM CHECKBOX STYLES *****/
.custom-checkbox[type="checkbox"] {
  display: none;
}
.custom-checkbox[type="checkbox"] + label {
  position: relative;
  display: flex;
  top: -2px;
}
.custom-checkbox[type="checkbox"] + label:before {
  content: "";
  border: 2px solid #e0e0e0;
  border-radius: 3px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: bottom;
  transition: all 300ms linear;
}

.custom-checkbox[type="checkbox"]:checked + label:before {
  height: 12px;
  width: 12px;
  min-width: 12px;
  display: inline-block;
  background: $accent-color;
  border: 2px solid $accent-color;
}
.custom-checkbox[type="checkbox"]:checked + label:after {
  content: "";
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  height: 9px;
  width: 3px;
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  left: 14px;
  top: 1px;
  background: transparent;
}
.custom-checkbox-container.error {
  color: $error-color;
}
.custom-checkbox-container.disabled label {
  opacity: 0.5;
  cursor: not-allowed;
}

/***** VALIDATED TOGGLE STYLES *****/
.toggle-container.error .radio-container {
  border: 1px solid $error-color;
}

/***** CLEARABLE SELECT STYLES *****/
.clearable-select {
  width: 100%;
  position: relative;
}
.clearable-select .custom-input.empty {
  color: $accent-text;
}
.clear-select {
  position: absolute;
  color: $accent-text;
  right: 16px;
  font-size: 16px;
  top: 0;
  cursor: pointer;
}

/***** ERROR/SUCCESS MESSAGES STYLES *****/
.error-message-container {
  position: relative;
}
.error-message {
  font-size: 13px;
  color: $error-color;
  position: absolute;
}
.error-message.center {
  width: 100%;
  text-align: center;
}
.success-message {
  @extend .error-message;
  color: #228b22;
}

/***** MULTISELECT STYLES *****/
.multiselect {
  width: 100%;
  height: 32px;
}
.multiselect > div {
  border-color: #edecec;
}
.multiselect.error > div {
  border-color: $error-color;
}
.multiselect > div:first-of-type {
  height: 32px;
  min-height: auto;
}
.multiselect .css-1492t68 {
  font-size: 13px;
  font-family: AvenirRoman;
  opacity: 0.5;
}
.css-2o5izw,
.css-2o5izw:hover {
  border-color: $accent-color !important;
  box-shadow: none !important;
  transition: border 300ms linear !important;
}
.css-1alnv5e:hover {
  background-color: #edecec !important;
  color: $accent-text-dark !important;
  transition: all 300ms linear;
  cursor: pointer;
}

/***** TEXTAREA STYLES *****/
textarea.custom-input {
  height: 60px;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  font-family: Avenir;
  resize: none;
}
