/***** HOME PAGE STYLES *****/
/*** Home Page Header Styles ***/
.home-page-header {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}
.home-page-profile-pic img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 675px) {
  .home-page-header {
    display: block;
    margin-bottom: 24px;
    text-align: center;
  }
  .home-page-header > .flex {
    display: block;
  }
  .home-page-header .xxl-text {
    font-size: 18px;
    margin-top: 8px;
  }
  .home-page-header .accent-text {
    font-size: 11px;
    margin-bottom: 16px;
  }
  .home-page-header .btn {
    width: 140px;
    margin: 0 auto;
  }
}

/*** Raffle Card Styles ***/
.raffles-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #edecec;
  padding: 0 8px;
}
.home-page .raffle-card .raffles-link {
  width: calc(100% - 184px);
  max-width: 100%;
}
.raffles-link p:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}
.percentage-bar {
  width: 100%;
}
.percentage-bar .bar {
  width: 100%;
  height: 6px;
  background: rgba(0, 164, 234, 0.12);
}
.percentage-bar .filled-in {
  position: absolute;
  background: #00a4ea;
  height: 6px;
  top: 0;
}
.raffle-card .view-details {
  text-align: right;
  margin-top: 24px;
}
@media only screen and (max-width: 1040px) {
  .raffles-link {
    margin-top: 24px;
  }
}
@media only screen and (max-width: 675px) {
  .raffle-card.half-width {
    width: 100%;
  }
  .raffle-card.half-width .card.mr-8 {
    margin-right: 0;
  }
  .raffle-card .xl-text {
    text-align: center;
  }
  .raffle-card .xl-text + div p {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-right: 64px;
  }
  .raffle-card .raffles-link {
    width: 100%;
  }
  .raffle-card .view-details {
    text-align: center;
  }
}

/*** Recently Viewed Styles ***/
.rv-cards > .card {
  width: 50%;
}
.rv-card {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  transition: box-shadow 300ms linear;
  cursor: pointer;
  margin-bottom: 16px;
}
.rv-card:hover {
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 2px 5px -2px rgba(0, 0, 0, 0.12);
}
.rv-card:last-child {
  margin-bottom: 0;
}
.rv-profile-pic img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 3px;
}
.rv-program {
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: all 300ms linear;
  margin-bottom: 16px;
  cursor: pointer;
}
.rv-program:hover {
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 2px 5px -2px rgba(0, 0, 0, 0.12);
}
.program-bullet {
  height: 16px;
  width: 16px;
  background: $accent-color;
  transform: rotate(-45deg);
}
.rv-card > *:nth-child(2) {
  margin-left: 16px;
  white-space: nowrap;
}
.rv-card > *:nth-child(2) p {
  white-space: nowrap;
}

/* Recently Viewed - Empty State Styles */
.rv-empty-state {
  margin: 80px 0;
  text-align: center;
}
.rv-empty-state img {
  max-height: 200px;
  max-width: 100%;
  height: auto;
}

/* Recently Viewed - Loading Styles */
.rv-card-loader {
  height: calc(100% - 80px);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 676px) and (max-width: 720px) {
  .rv-cards > .card {
    padding: 16px 8px;
    width: calc(50% - 8px);
  }
  .rv-cards > .card.mr-20 {
    margin-right: 8px;
  }
  .rv-cards > .card.ml-20 {
    margin-left: 8px;
  }
  .rv-cards > .card > p.mb-32 {
    margin-bottom: 16px;
  }
  .rv-card {
    padding: 8px;
  }
  .program-bullet {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-top: 4px;
  }
}
@media only screen and (max-width: 675px) {
  .rv-cards.flex {
    display: block;
  }
  .rv-cards > .card {
    width: 100%;
    margin: 0 0 24px 0;
    padding: 16px;
  }
  .rv-cards > .card:last-child {
    margin-bottom: 0;
  }
  .rv-cards > .card .large-text {
    font-size: 14px;
  }
  .rv-cards > .card > .large-text {
    margin-top: 8px;
    margin-bottom: 24px;
    text-align: center;
  }
  .rv-cards > .card .small-text {
    font-size: 11px;
  }
}
