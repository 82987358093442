.card {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px -3px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.04);
  padding: 32px;
}
.card.flat-card {
  box-shadow: none;
}
