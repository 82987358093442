/***** RSVP PAGE STYLES *****/
.rsvp-page {
  padding: 40px 0;
  background: #fff;
  min-height: calc(100vh - 72px);
}
@media only screen and (max-width: 1040px) {
  .rsvp-page {
    min-height: calc(100vh - 56px);
  }
}
@media only screen and (max-width: 560px) {
  .rsvp-page {
    padding: 0 0 32px 0;
  }
  .rsvp-page .card {
    padding: 16px;
  }
}

.events-resources-lists {
  display: flex;
}
.events-resources-tabs {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.rsvp-tab-link {
  padding-bottom: 16px;
  text-transform: uppercase;
}
.rsvp-tab-link-active {
  @extend .rsvp-tab-link;
  border-bottom: 2px solid #2774ae;
  color: #2774ae;
}

/***** RSVP LOCATION HOME PAGE STYLES ****/
/*** Header Styles ***/
.rsvp-location-header {
  height: 440px;
  margin: 0 0 40px;
  position: relative;
  padding: 32px 48px 16px;
  color: #fff;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.32);
  font-size: 20px;
  font-family: AvenirHeavy;
  margin-top: -40px;
  color: rgba(255, 255, 255, 0.87);
}
.rsvp-location-header-img-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #000;
}
.rsvp-location-header-img-container .location-img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.rsvp-location-header .container {
  position: relative;
  z-index: 1;
  max-width: 892px;
  width: 100%;
  margin: 0 auto;
}
.rsvp-location-header .location-logo {
  height: 120px;
  margin: 0 auto 16px;
  display: block;
}
.rsvp-location-header .location-title {
  font-size: 48px;
  font-family: AvenirHeavy;
  line-height: 56px;
  text-align: center;
  color: #fff;
}
@media only screen and (max-width: 1040px) {
  .rsvp-location-header {
    padding: 40px 0;
    margin: 0 0 40px 0;
    height: 260px;
    font-size: 18px;
  }
  .rsvp-location-header .location-title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 560px) {
  .rsvp-location-header {
    margin: 0 -16px 24px;
    padding: 12px 0;
    font-size: 14px;
    height: auto;
    min-height: 200px;
  }
  .rsvp-location-header .location-title {
    font-size: 20px;
    line-height: 26px;
  }
}
/*** Container Styles ***/
.rsvp-container {
  width: 100%;
  margin: 0;
}
@media only screen and (max-width: 560px) {
  .rsvp-container {
    padding: 16px;
  }
}

/*** Events List/Grid Styles ***/
.rsvp-section-title {
  font-size: 32px;
}
.rsvp-events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 354px);
  grid-gap: 26px;
}

@media only screen and (max-width: 560px) {
  .rsvp-events-grid {
    grid-template-columns: repeat(auto-fit, 300px);
  }
}

@media only screen and (max-width: 560px) {
  .rsvp-section-title {
    font-size: 20px;
  }
}

.rsvp-container .btn-top {
  position: fixed;
  bottom: 100px;
  right: 32px;
  font-size: 18px;
  border: none;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}
/* Event Card Styles */
.rsvp-event-card.card {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-color: #d8d8dd;
}
.rsvp-event-img {
  width: calc(100% + 32px);
  object-fit: cover;
  margin-top: -24px;
  margin-left: -16px;
  border-radius: 3px 3px 0 0;
}
.rsvp-event-date {
  position: relative;
  background: $accent-color;
  color: #fff;
  height: 72px;
  width: 64px;
  border: 4px solid #fff;
  border-radius: 4px;
  margin: -32px auto 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rsvp-event-description.hide-more-description {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media only screen and (max-width: 560px) {
  .event-details-header {
    display: flex;
    align-items: flex-end;
    margin-top: -8px;
    margin-bottom: 16px;
  }
  .event-details-header .rsvp-event-date {
    margin: 0 8px 0 0;
  }
  .event-details-header p.text-center.mb-8 {
    margin-bottom: 0;
    text-align: left;
  }
  .rsvp-event-card.card {
    width: 100%;
  }
}

/* Resources List Styles */
.rsvp-resources-container {
  border: 1px solid #d8d8dd;
  padding: 25px;
  height: fit-content;
  margin-left: 16px;
  max-width: 370px;
}
.rsvp-resources-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.rsvp-resources-card:not(:last-child) {
  margin-bottom: 32px;
}
.rsvp-resource-img {
  height: 104px;
  width: 104px;
  object-fit: cover;
}
.rsvp-resources-no-results-img {
  object-fit: cover;
  display: block;
  height: 200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}
.rsvp-resources-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
}
@media only screen and (max-width: 560px) {
  .rsvp-resources-container {
    border: none;
    padding: 16px;
  }
}

/***** RSVP REGISTRATION STYLES ****/
/*** Header Styles ***/
@media only screen and (max-width: 1040px) {
  .rsvp-event-registration-header {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 560px) {
  .rsvp-event-registration-header {
    padding: 16px 16px 0;
  }
}
/*** Sidebar Styles ***/
.rsvp-sidebar .rsvp-event-card {
  min-width: 256px;
  width: 256px;
  margin-right: 24px;
}
.rsvp-sidebar .rsvp-event-card {
  height: auto;
}
@media only screen and (max-width: 1040px) {
  .rsvp-sidebar .rsvp-event-card {
    min-width: 184px;
    width: 184px;
  }
}
@media only screen and (max-width: 560px) {
  .rsvp-sidebar .rsvp-event-card {
    margin-bottom: 0;
    border: none;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}
/*** Seating Options Styles ***/
.seating-options-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
.table-card {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.table-card.selected {
  border: 2px solid $accent-color;
}
.table-card-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #edecec;
}
/*** Terms and Conditions Styles ***/
.rsvp-terms-card > .xxl-text {
  border-bottom: 1px solid #edecec;
  padding-bottom: 8px;
}
.policy-textbox {
  background: #fafafa;
  border-radius: 3px;
  height: 120px;
  overflow: auto;
  padding: 16px;
  color: $accent-text-dark;
  line-height: 1.5;
  border: 1px solid #edecec;
  margin-bottom: 24px;
}
.policy-checkbox.custom-checkbox-container label {
  line-height: 1.5;
}
.policy-checkbox.custom-checkbox-container label:before {
  margin-top: 4px;
}
.policy-checkbox.custom-checkbox-container
  .custom-checkbox[type="checkbox"]:checked
  + label:after {
  left: 6px;
  top: 5px;
}
.rsvp-state-card .rsvp-state-card-title {
  font-size: 34px;
}
@media only screen and (max-width: 560px) {
  .rsvp-event-registration-container {
    padding: 0;
  }
  .seating-options-grid {
    grid-template-columns: 1fr;
  }
  .seating-options-card {
    border-bottom: none;
  }
  .seating-options-card + .flex.flex-justify-end button {
    width: calc(100% - 32px);
    left: -16px;
    position: relative;
  }
  .rsvp-terms-card {
    border-bottom: none;
  }
  .rsvp-terms-card > .xxl-text {
    border-bottom: none;
  }
  .rsvp-form-btns {
    padding: 0 16px;
  }
  .rsvp-form-btns .error-text {
    bottom: -24px;
    margin-right: 16px;
  }
  .rsvp-state-card {
    border-bottom: 0;
  }
  .rsvp-state-card img {
    height: 160px;
  }
  .rsvp-state-card .rsvp-state-card-title {
    font-size: 24px;
  }
  .rsvp-terms-card .input-container {
    height: auto;
  }
}
/*** Google Calendar Integration Styles ***/
.rsvp-google-calendar-btn {
  display: flex;
  align-items: center;
}
.rsvp-google-calendar-btn img {
  height: 28px;
  margin-right: 8px;
}
.rsvp-event-card .rsvp-google-calendar-btn {
  padding: 0px 8px;
  font-size: 12px;
}
.rsvp-event-card .rsvp-google-calendar-btn img {
  height: 22px;
}

/***** RSVP Standalong Payment Page Styles *****/
.rsvp-payment-page {
  @extend .trip-page;
  padding-left: unset;
}

.rsvp-payment-section {
  margin-left: 200px;
}

.rsvp-payment-btn {
  @extend .trip-form-checkout-btns;
  max-width: 450px; // match width of stripe-info-container
  flex-direction: row-reverse;
}

.rsvp-payment-error {
  max-width: 620px;
  width: 100%;
  margin: 0 auto;
  line-height: 1.5;
}

@media only screen and (max-width: 560px) {
  .rsvp-payment-page {
    padding: 16px 16px 60px 16px;
  }

  .rsvp-payment-header {
    padding-left: 16px;
  }

  .rsvp-payment-section {
    margin-left: unset; // page-wrapper-mobile already adds has padding
  }

  .rsvp-payment-btn {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 561px) and (max-width: 750px) {
  .rsvp-payment-section {
    margin-left: 25px; // tablet needs some margin but not the full 200px
  }
}
