/***** TAG STYLES *****/
.tag-container {
  margin-bottom: 24px;
}
.tag {
  padding: 0 8px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: none;
  font-family: AvenirMedium;
  display: inline-flex;
  align-items: center;
}
.tag-container .tag {
  margin-bottom: 8px;
}

/*** Tag Sizes Styles ***/
.tag.small {
  height: 24px;
}
.tag.medium {
  height: 32px;
}

@media only screen and (max-width: 560px) {
  .tag.small {
    height: 18px;
    font-size: 10px;
    font-family: AvenirBook;
    letter-spacing: 0.5px;
  }
}

/*** Tag Colors Styles ***/
.tag.primary {
  background: $accent-color-secondary;
  color: #fff;
}
.tag.light {
  background: rgba(237, 236, 236, 0.6);
  color: $accent-text-dark;
}
/* Program Tag Colors */
.program-tag-orange {
  background: rgba(243, 137, 61, 0.08);
  color: #f3893d;
}
.program-tag-green {
  background: rgba(99, 198, 116, 0.08);
  color: #63c674;
}

/*** Tag Rounded Styles ***/
.tag.rounded {
  border-radius: 19.5px;
  padding: 0 20px;
  margin-right: 8px;
}
