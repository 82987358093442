.btn {
  border-radius: 3px;
  font-family: AvenirBook;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-size: 14px;
  transition:
    background 300ms linear,
    color 300ms linear,
    opacity 300ms linear;
  cursor: pointer;
  white-space: nowrap;
}
a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:focus {
  outline: none;
}
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn-large {
  height: 48px;
  line-height: 48px;
}
.btn-medium {
  height: 32px;
  line-height: 32px;
}
.btn-fullWidth {
  width: 100%;
}
.btn-accent {
  background: $accent-color;
  color: #fff;
  border: 1px solid $accent-color;
}
.btn-accent:hover:not(:disabled) {
  background: #fff;
  color: $accent-color;
}
.btn-accent-reverse {
  background: #fff;
  color: $accent-color;
  border: 1px solid $accent-color;
}
.btn-accent-reverse:hover:not(:disabled) {
  background: $accent-color;
  color: #fff;
}
.btn-light {
  background: #edecec;
  color: #333;
  border: 1px solid #edecec;
}
.btn-light:hover:not(:disabled) {
  background: #333;
  color: #edecec;
}
