/***** HEADER STYLES *****/
.header {
  position: fixed;
  width: 100vw;
  background: #fff;
  height: 72px;
  box-shadow:
    0 2px 5px -3px rgba(0, 0, 0, 0.08),
    0 2px 16px -2px rgba(0, 0, 0, 0.08);
  z-index: 100;
}
.header .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1040px) {
  .header {
    height: 56px;
  }
}
@media only screen and (max-width: 560px) {
  .header {
    z-index: 200;
  }
  .header .container {
    grid-template-columns: repeat(2, auto);
  }
}

/*** Nav Styles ***/
nav {
  height: 100%;
  justify-self: center;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.nav-link a {
  display: flex;
  align-items: center;
  margin: 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  height: 100%;
  font-family: AvenirHeavy;
  white-space: nowrap;
}
a.active-nav-link {
  color: #2774ae;
  border-bottom: 2px solid #2774ae;
}
@media only screen and (max-width: 720px) {
  .nav-link a {
    margin: 0 8px;
  }
}

/*** BH Styles ***/
.bh {
  font-size: 12px;
  position: absolute;
  right: 32px;
  top: 12px;
}
@media only screen and (max-width: 1040px) {
  .bh {
    top: 8px;
  }
}
@media only screen and (max-width: 648px) {
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .bh {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 8px;
  }
}
/*** Account Dropdown Styles ***/
.account-dropdown {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
/* Account Dropdown Header Styles */
.account-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.account-dropdown-header .user-profile-pic {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-header .user-name {
  margin-left: 8px;
}
.account-dropdown-header .user-name i {
  vertical-align: middle;
  margin-top: -3px;
}
/* Account Dropdown Box Styles */
.dropdown-box {
  background: #fff;
  border-radius: 3px;
  padding: 16px;
  position: absolute;
  box-shadow:
    0 0 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
  transition: opacity 300ms linear;
  opacity: 0;
  visibility: hidden;
}
.dropdown-box.open {
  opacity: 1;
  visibility: visible;
}
.account-dropdown-box {
  width: 220px;
  right: 0;
  top: 54px;
}
.account-dropdown-box-header {
  border-bottom: 1px solid #edecec;
  padding-bottom: 12px;
  margin-bottom: 16px;
  display: flex;
}
.account-dropdown-box-header img {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 100%;
}
.account-dropdown-box-header .user-name {
  margin: 2px 0 6px 0;
}
.account-dropdown-box-body p {
  transition: color 300ms linear;
}
.account-dropdown-box-body p:last-child:hover {
  color: rgba(0, 0, 0, 0.38);
  cursor: pointer;
}
