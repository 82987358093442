/***** PROFILE FORM STYLES *****/
.profile-form-header {
  position: sticky;
  top: 56px;
  z-index: 2;
  padding: 16px 0;
  margin-top: -16px;
  background: #fff;
}
.profile-form-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 220px));
  grid-gap: 16px 40px;
}
.profile-form .profile-form-section {
  margin-bottom: 40px;
}
.profile-form .profile-form-section .section-title {
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 16px;
}
.profile-form .input-container {
  height: 48px;
  position: relative;
}
.profile-form .input-container .error-text {
  position: absolute;
  bottom: -15px;
  left: 16px;
  font-size: 12px;
}
.profile-form .required-text {
  color: $error-color;
}
.profile-form .input-container-select__control {
  border: none;
  background: transparent;
  position: relative;
  top: -8px;
  font-size: 12px;
  left: -8px;
  width: calc(100% + 16px);
}
.profile-form .input-container-select__control--is-focused {
  border: none;
  outline: none !important;
  box-shadow: none;
}
.profile-form .input-container-select__indicator-separator {
  display: none;
}
.profile-form .phone-input,
.profile-form .intl-tel-input {
  height: 18px;
  border: none;
}
.profile-form .custom-checkbox[type="checkbox"]:checked + label:after {
  left: 6px;
}
.profile-form .input-container .react-datepicker-popper {
  z-index: 2;
}
.school-error-message {
  font-size: 12px;
}
.profile-school-loader svg#loading {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 16px;
}
.profile-form .btn {
  width: 120px;
}
.save-btn-container .error-message {
  white-space: nowrap;
  right: 0;
  bottom: -16px;
}
.success-text {
  position: absolute;
  white-space: nowrap;
  right: 0;
  top: 52px;
  font-size: 13px;
  color: #2d7c3b;
}
@media only screen and (max-width: 1040px) {
  .profile-form-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 840px) {
  .profile-form .input-container.jewishIsraelKnowledgeLevel-select {
    height: 60px;
  }
}
@media only screen and (max-width: 560px) {
  .profile-form-header {
    position: unset;
    background: transparent;
  }
  .my-profile-page .profile-card {
    background: transparent;
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .my-profile-page .page-title {
    display: none;
  }
  .profile-form-grid {
    grid-template-columns: 1fr;
  }
  .school-error-message {
    margin-top: 2px;
    font-size: 10px;
  }
  .profile-form .btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .success-text {
    width: 100%;
    text-align: center;
  }
}

/* Profile Pic Styles */
.profile-pic-container img,
.profile-pic-container svg#loading {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 44px;
}
@media only screen and (max-width: 560px) {
  .flex.profile-pic-container {
    display: flex;
  }
  .profile-pic-container img {
    margin-right: 32px;
  }
}

/***** MODAL PROFILE FORM STYLES *****/
.modal .page-title {
  font-size: 20px;
}
.modal .profile-form .save-btn-container {
  position: unset;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  grid-column-start: span 2;
}
@media only screen and (max-width: 560px) {
  .modal .profile-form .save-btn-container {
    grid-column-start: 1;
  }
}
