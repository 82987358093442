/***** TABLE HEADER STYLES *****/
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.table-header-left {
  display: flex;
  align-items: center;
}
.table-header-left p {
  margin-left: 8px;
}
.table-header-left p:nth-child(2) {
  height: 16px;
}
.view-select {
  border: none;
  font-size: 14px;
  font-family: AvenirHeavy;
  width: 64px;
}
.view-select > .view-select__control {
  border: none;
  background: transparent;
}
.view-select__single-value {
  color: $accent-color !important;
}
.view-select__indicator-separator {
  display: none;
}
.view-select__dropdown-indicator {
  color: $accent-color !important;
}
.table-header-left i.accent-text-secondary {
  vertical-align: middle;
  font-size: 16px;
}

@media only screen and (max-width: 560px) {
  .table-header {
    display: block;
  }
  .table-header-left,
  .view-select select {
    font-size: 11px;
  }
  .table-header > div:first-child {
    display: block !important;
  }
}

/***** TABLE ROW STYLES *****/
.table-row {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  transition: box-shadow 250ms linear;
  cursor: pointer;
  padding: 12px 16px;
  margin-bottom: 12px;
}
.table-row:hover {
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow:
    0 2px 5px -2px rgba(0, 0, 0, 0.12),
    0 2px 18px 2px rgba(0, 0, 0, 0.08);
}
.table-label {
  text-transform: uppercase;
  color: $accent-text;
  margin-bottom: 8px;
  font-size: 11px;
  font-family: AvenirMedium;
}
.table-data {
  line-height: 1.2;
}
.table-data.email {
  word-wrap: break-word;
  word-break: break-all;
}
.table-data.email span {
  display: inline-block;
  max-width: calc(100% - 24px);
  vertical-align: top;
}
.table-data-sub {
  color: $accent-text-dark;
  font-size: 12px;
  margin-top: 2px;
}
@media only screen and (max-width: 560px) {
  .full-width-table {
    margin: 0 -16px;
  }
  .table-rows {
    height: calc(100vh - 188px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  // [mobilemenuopen] .table-rows, [accountdropdownopen] .table-rows {overflow: hidden;}
  .table-rows .table-row {
    margin: 0;
    border-radius: 0;
  }
}
/***** TABLE PAGINATION STYLES *****/
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
.pagination li {
  margin: 4px;
  padding: 4px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms linear;
}
.pagination li:hover {
  opacity: 0.5;
}
.pagination li.active {
  background: $accent-color;
  color: #fff;
}
.pagination-arrow i {
  vertical-align: middle;
  font-size: 16px;
}
.pagination-arrow:first-child {
  margin-right: 8px;
  margin-left: 0;
}
.pagination-arrow:last-child {
  margin-left: 8px;
  margin-right: 0;
}
@media only screen and (max-width: 400px) {
  .pagination li {
    min-width: 20px;
    height: 20px;
    width: 20px;
  }
}
