.stripe-card-element {
  max-width: 450px;
  border: 1px solid #edecec;
  border-radius: 3px;
  padding: 12px;
  height: 45px;
  transition: border 300ms linear;
  background: #fff;
  font-family: AvenirRoman;
  font-size: 13px;
}

.stripe-card-element.error {
  border: 1px solid $error-color;
}

.stripe-info-container {
  max-width: 450px;
}
